import { tw } from '@/utils/tw';
import BaseRowTheme from 'base/components/Row/theme';

const { Button } = BaseRowTheme;

const Row = tw.theme({
  extend: BaseRowTheme,
  slots: {
    base: [],
    headline: [`text-gabor-sm`, `sm:text-gabor-md`, `font-secondary`, `-mb-3`, `uppercase`],
    caption: [`text-paragraph-md`],
    footer: [`[&:not(:first-child)]:mt-6.5`],
  },
});

export default Object.assign(Row, { Button });
