import { ImageTheme } from '@/components/Image/theme';
import { tw } from '@/utils/tw';
import BaseArticleTeaserDefaultTheme from 'base/components/ArticleTeaser/Default/theme';

const Image = tw.theme({
  extend: ImageTheme,
  base: `aspect-default`,
});

const ArticleTeaserDefault = tw.theme({
  extend: BaseArticleTeaserDefaultTheme,
  slots: {
    caption: `text-preview-sm uppercase text-gray-800`,
    description: `text-preview-sm`,
    headline: `font-primary md:grid-cols-8:text-headline-lg`,
  },
  variants: {
    variant: {
      horizontal: {
        base: `grid-cols-1 md:auto-rows-min md:grid-cols-2 md:gap-4`,
        description: `sm:grid-cols-7:text-preview-lg sm:grid-cols-7:mt-3`,
        headline: `text-headline-xs sm:text-headline-sm sm:grid-cols-7:text-headline-sm`,
      },
      vertical: {
        base: `gap-3`,
        description: `sm:text-preview-lg`,
        headline: `grid-cols-4:text-headline-2xs text-headline-sm grid-cols-7:text-headline-sm grid-cols-8:text-headline-sm sm:text-headline-sm sm:grid-cols-4:text-headline-sm sm:grid-cols-7:text-headline-sm md:grid-cols-4:leading-[1.15] md:grid-cols-4:text-headline-sm md:grid-cols-8:leading-[1.05]`,
      },
    },
  },
});

export default Object.assign(ArticleTeaserDefault, { Image });
